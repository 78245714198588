<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Jenis Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="formData.name"
                v-validate="'required'"
                class="uk-input"
                type="numeric"
                name="jenis_kandang"
                placeholder="Masukan jenis kandang..."
                :class="{'uk-form-danger': errors.has('jenis_kandang')}"
              >
              <div
                v-show="errors.has('jenis_kandang')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('jenis_kandang') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Daya Tampung (ekor)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model.number="formData.capacity"
                v-validate="'integer|required'"
                class="uk-input"
                type="numeric"
                name="daya_tampung"
                placeholder="0"
                :class="{'uk-form-danger': errors.has('daya_tampung')}"
              >
              <div
                v-show="errors.has('daya_tampung')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('daya_tampung') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tingkat
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model.number="formData.floor"
                v-validate="'integer|required'"
                class="uk-input"
                type="numeric"
                name="tingkat"
                placeholder="0"
                :class="{'uk-form-danger': errors.has('tingkat')}"
              >
              <div
                v-show="errors.has('tingkat')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('tingkat') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Panjang (m)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model.number="formData.height"
                v-validate="'integer|required'"
                class="uk-input"
                type="numeric"
                name="panjang"
                placeholder="0"
                :class="{'uk-form-danger': errors.has('panjang')}"
              >
              <div
                v-show="errors.has('panjang')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('panjang') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Lebar
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model.number="formData.width"
                v-validate="'integer|required'"
                class="uk-input"
                type="numeric"
                name="lebar"
                placeholder="0"
                :class="{'uk-form-danger': errors.has('lebar')}"
              >
              <div
                v-show="errors.has('lebar')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('lebar') }}
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kipas
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model.number="formData.fan"
                v-validate="'integer|required'"
                class="uk-input"
                type="numeric"
                name="kipas"
                placeholder="0"
                :class="{'uk-form-danger': errors.has('kipas')}"
              >
              <div
                v-show="errors.has('kipas')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('kipas') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Pemanas
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model.number="formData.heater"
                v-validate="'integer|required'"
                class="uk-input"
                type="numeric"
                name="pemanas"
                placeholder="0"
                :class="{'uk-form-danger': errors.has('pemanas')}"
              >
              <div
                v-show="errors.has('pemanas')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('pemanas') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Bagi Hasil Mitra
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model.number="formData.sharing_percentage"
                v-validate="{ required: true, regex:/^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$/ }"
                class="uk-input"
                type="numeric"
                name="sharing"
                placeholder="%"
                :class="{'uk-form-danger': errors.has('sharing')}"
              >
              <div
                v-show="errors.has('sharing')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('sharing') }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Total Dana Jaminan
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-inline">
                <span
                  class="uk-form-icon label-green"
                  uk-icon
                >
                  Rp
                </span>
                <input
                  v-model="formatInstallmentAmount"
                  v-validate="{ required: true, regex:'^[0-9.]+$' }"
                  class="uk-input"
                  maxlength="13"
                  type="text"
                  name="installment_amount"
                  placeholder="0"
                  :class="{'uk-form-danger': errors.has('installment_amount') || errorsInstallmentAmount}"
                >
              </div>
              <div
                v-show="errors.has('installment_amount') || errorsInstallmentAmount"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('installment_amount') || errorsInstallmentAmount }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div
              class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl"
              style="display:flex"
            >
              <input
                v-model="statusSwitch"
                class="switcher"
                type="checkbox"
              >
              <span
                v-if="statusSwitch"
                class="uk-text-success label-unbackground"
              >{{ status.ACTIVE }}</span>
              <span
                v-else
                class="uk-text-danger label-unbackground"
              >{{ status.INACTIVE }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="uk-width-1-1 uk-text-right uk-margin-top">
        <button
          class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
          type="button"
          @click="goToBack"
        >
          <img
            v-lazy="`${images}/icon/close.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Batal
        </button>
        <button
          class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
          type="button"
          @click="showSaveConfirmModal"
        >
          <img
            v-lazy="`${images}/icon/save.svg`"
            alt=""
            class="uk-margin-small-right"
          >
          Simpan
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import { PREFIX_IMAGE, STATUS } from '@/utils/constant'
import { thousandSeparator } from '@/utils/formater'

export default {
  data() {
    return {
      isShowFarm: false,
      isLoading: false,
      images: PREFIX_IMAGE,
      status: STATUS,
      datepick: new Date(),
      statusSwitch: true,
      formData: {
        status: true
      },
      formatInstallmentAmount:'',
      maxInstallmentAmount: 2000000000,
      errorsInstallmentAmount:'',
      previousNumber: ''
    }
  },
  watch: {
    'formatInstallmentAmount'(newValue) {
      let valueString = String(newValue)
      const newValueWithoutDots = Number(valueString.replace(/\./g, ''))
      if (isNaN(newValueWithoutDots)) {
        this.formatInstallmentAmount = this.previousNumber
      } else {
        if(newValueWithoutDots > this.maxInstallmentAmount){
          this.formatInstallmentAmount = this.thousandSeparator(newValueWithoutDots)
          this.previousNumber = this.thousandSeparator(newValueWithoutDots)
          this.errorsInstallmentAmount= 'Maximum installment_amount = 2.000.000.000'
        } else {
          this.errorsInstallmentAmount = ''
          this.formatInstallmentAmount = this.thousandSeparator(newValueWithoutDots)
          this.previousNumber = this.thousandSeparator(newValueWithoutDots)
        }
      }
    }
  },
  methods: {
    thousandSeparator (v){
      return thousandSeparator (v)
    },
    ...mapMutations({
      setModalAdd: 'cageCategory/SET_MODAL_ADD'
    }),
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return
          this.formData.installment_amount = Number(String(this.formatInstallmentAmount).replace(/\./g, ''))
          this.formData.status = this.statusSwitch ? true : false
          this.setModalAdd(this.formData)
          window.UIkit.modal('#modal-save-confirm').show()
        }
      })
    }
  }
}
</script>
